import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

// List of customers of a vendor
const vendorOrganizationList = async ({ commit }, vendorId) => {
  try {
    const response = await axios.get(`accounts/v1/vendor/${vendorId}/clinics`);
    commit("setVendorClinics", response.data || [])
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const updateOrgsInPricingTier = async ({ commit }, payload) => {
  const body = payload.organizations; // orgs is an array of objects with id and name keys
  const deletedOrgInTiers = payload.deletedOrgInTiers;

  const { tierId } = payload;
  const { vendorId } = payload;
  const proxy = new PutSimpatraServiceProxy();
  let query = `products/v1/vendor/${vendorId}/tier/${tierId}/member`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully updated pricing tier!",
  });
  if (response) {
    const data = response.data || null
    await commit("updateTierMemberList", {
      data,
      body,
      deletedOrgInTiers,
    });
    return data;
  }
};

const updateTierFee = async (_, payload) => {
  const body = {
    marketFee: payload.marketFee,
  };
  const { tierId } = payload;
  const { vendorId } = payload;

  try {
    await axios.put(`products/v1/vendor/${vendorId}/tier/${tierId}/marketfee`, body);
    notification("success", "Tier fee updated successfully!");
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

export default {
  vendorOrganizationList,
  updateOrgsInPricingTier,
  updateTierFee,
};
