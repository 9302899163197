import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import axios from "@/lib/axios";
import notification from "@/utils/notifications";

const fetchProductTierPricing = async ({ commit }, { productId, vendorId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `products/v1/vendor/${vendorId}/product/${productId}/tier/`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setProductPricing", data);
    return data;
  }
};

const addNewProduct = async ({ getters }, { vendorId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const currentProduct = getters.getProductDetailsForm;

  const body = {
    productName: currentProduct.name,
    description: currentProduct.description,
    sku: currentProduct.sku,
    vendorSku: currentProduct.vendorSku,
    mfgSku: currentProduct.mfgSku,
    registrationRequired: currentProduct.registrationRequired,
    boundByState: currentProduct.boundByState,
    categorySlug: currentProduct.categorySlug,
    subcategorySlug: currentProduct.subcategorySlug,
    weight: currentProduct.weight,
    weightTypeId: currentProduct.weightTypeId,
    cardVolume: currentProduct.cardVolume,
    limited: currentProduct.limited,
    limitedQty: currentProduct.limitedQty,
    minQtyForPurchase: currentProduct.minQtyForPurchase,
    marketplaceStoreFront: currentProduct.marketplaceStoreFront,
    practiceStoreFront: currentProduct.practiceStoreFront,
    prescriptionStoreFront: currentProduct.prescriptionStoreFront,
    childProducts: [],
    isBundle: false,
    rateType: currentProduct.rateType,
    labtestId: currentProduct.labtestId,
    labFee: currentProduct.labFee,
    isVisibleMedcenter: currentProduct.isVisibleMedcenter,
    isVirtualProduct: currentProduct.isVirtualProduct,
  };

  const query = `products/v1/vendor/${vendorId}/product`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added product!",
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

// NEED TO UPDATE THE SETTER
const updateProduct = async ({ commit, getters, state }, { payload, vendorId }) => {
  const proxy = new PutSimpatraServiceProxy();
  const isBundleFlag = payload.ispackage;

  const productId = state.productDetailsForm.id;
  const isPrescriptionProduct = payload.subcategorySlug === "503A" || payload.prescriptionStoreFront

  const body = {
    productName: payload.name,
    description: payload.description,
    sku: payload.sku,
    vendorSku: payload.vendorSku,
    mfgSku: payload.mfgSku,
    registrationRequired: payload.registrationRequired,
    boundByState: payload.boundByState,
    categorySlug: payload.categorySlug,
    subcategorySlug: payload.subcategorySlug,
    weight: payload.weight,
    weightTypeId: payload.weightTypeId,
    cardVolume: payload.cardVolume,
    limited: payload.limited,
    limitedQty: payload.limitedQty,
    minQtyForPurchase: isPrescriptionProduct ? 0 : payload.minQtyForPurchase,
    marketplaceStoreFront: payload.marketplaceStoreFront,
    practiceStoreFront: payload.practiceStoreFront,
    prescriptionStoreFront: payload.prescriptionStoreFront,
    childProducts: payload.childProducts,
    isBundle: isBundleFlag,
    rateType: payload.rateType,
    labtestId: payload.labtestId,
    labFee: payload.labFee,
    isVisibleMedcenter: payload.isVisibleMedcenter,
    isVirtualProduct: payload.isVirtualProduct,
  };

  const query = `products/v1/vendor/${vendorId}/product/${productId}`;
  const response = await proxy.execute(query, body);

  if (getters.getProductDetailsForm.productBundle.length > 0) {
    const value = [];
    getters.getProductDetailsForm.productBundle.forEach((product) => {
      value.push(product.childProduct);
    });
    commit("setProductsBundle", value);
  }

  if (response) {
    const { data } = response;
    return data;
  }
};

const addNewBundleProduct = async ({ commit }, { payload, vendorId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const currentProduct = payload;
  const isPrescriptionProduct = currentProduct.subcategorySlug === "503A" || currentProduct.prescriptionStoreFront

  const body = {
    productName: currentProduct.name,
    description: currentProduct.description,
    sku: currentProduct.sku,
    vendorSku: currentProduct.vendorSku,
    mfgSku: currentProduct.mfgSku,
    registrationRequired: currentProduct.registrationRequired,
    boundByState: currentProduct.boundByState,
    categorySlug: currentProduct.categorySlug,
    subcategorySlug: currentProduct.subcategorySlug,
    weight: currentProduct.weight,
    weightTypeId: currentProduct.weightTypeId,
    cardVolume: currentProduct.cardVolume,
    limited: currentProduct.limited,
    limitedQty: currentProduct.limitedQty,
    minQtyForPurchase: isPrescriptionProduct ? 0 : currentProduct.minQtyForPurchase,
    marketplaceStoreFront: currentProduct.marketplaceStoreFront,
    practiceStoreFront: currentProduct.practiceStoreFront,
    prescriptionStoreFront: currentProduct.prescriptionStoreFront,
    childProducts: currentProduct.childProducts,
    isBundle: true,
    rateType: currentProduct.rateType,
    labtestId: currentProduct.labtestId,
    labFee: currentProduct.labFee,
    isVisibleMedcenter: currentProduct.isVisibleMedcenter,
    isVirtualProduct: currentProduct.isVirtualProduct,
  };

  const query = `products/v1/vendor/${vendorId}/product`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added bundle!",
  });

  if (response) {
    const { data } = response;

    const value = [];
    data.productBundle.forEach((product) => {
      value.push(product.childProduct);
    });

    commit("setProductsBundle", value);
    return data;
  } else {
    commit("setProductsBundle", null);
    return null;
  }
};

const addImagesToProduct = async ({ commit }, payload) => {
  const proxy = new PutSimpatraServiceProxy();
  const body = payload.images;
  const { productId } = payload;
  const { vendorId } = payload;
  const query = `products/v1/vendor/${vendorId}/product/${productId}/image`;
  const response = await proxy.execute(query, body);

  if (response) {
    const { data } = response;
    commit("setCurrentVendorProduct", data);
    return data;
  } else {
    return null;
  }
};

const updateCurrentProductFiles = async (_, { productId, files, vendorId }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `products/v1/vendor/${vendorId}/product/${productId}/file`;
  const response = await proxy.execute(query, files, {
    displaySuccessMessage: true,
    successNotificationMsg: "Current product files updated!",
  });
  if (response) {
    const { data } = response;
    return data;
  } else {
    return null;
  }
};

export default {
  addNewProduct,
  updateProduct,
  addNewBundleProduct,
  fetchProductTierPricing,
  addImagesToProduct,
  updateCurrentProductFiles,
};
