const getUrlExtension = url => {
  return url
    .split(/[#?]/)[0]
    .split(".")
    .pop()
    .trim();
};

const convertBlobFileUrl = blobFile => {
  return window.URL.createObjectURL(
    new Blob([blobFile], {
      type: blobFile.type,
    }),
  );
};

const convertBase64ToFile = (base64String, fileName) => {
  // Convert base64 string back to binary data
  const binaryData = atob(base64String.split(",")[1]);
  const array = [];
  for (let i = 0; i < binaryData.length; i++) {
    array.push(binaryData.charCodeAt(i));
  }
  const blob = new Blob([new Uint8Array(array)], { type: "image/jpg" });
  const file = new File([blob], fileName || "signature.jpg", { type: "image/jpg" });
  return file;
  // You can now use the File object as needed
};

export {
  getUrlExtension,
  convertBlobFileUrl,
  convertBase64ToFile,
};

