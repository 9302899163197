import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

const fetchWarehouseInventoryLots = async ({ commit }, vendorId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/vendor/${vendorId}/allcodes`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setInventorySlots", data);
    return data;
  }
};

const uniteKitCodes = async ({ commit }, { vendorId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/vendor/${vendorId}/uniteCode`;

  // Invalid code! = return when Code is already united
  // Invalid Code! = return when Return Shipping Label is already united
  // ^ need to update the response error message if we want to update the message to each scenario
  const response = await proxy.execute(query, payload, {
    successNotificationMsg: `Successfully united the code: ${payload.activationCode}`,
    displaySuccessMessage: true,
    returnError: true,
    errorNotificationMsg: "Code or Return Shipping Label already united.",
  });

  if (response && response.data && !response.data.error) {
    commit("updateActivationCodeStatus", response.data);
    return response.data;
  }
};

const generateKitCodes = async ({ commit }, { labId, vendorId, productId, codeToGenerate }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/vendor/${vendorId}/product/${productId}/codegenerate/${codeToGenerate}`;
  const body = { labId };
  const notificationMsg = {
    displaySuccessMessage: true,
    successNotificationMsg: "Activation codes generated!",
  };
  const response = await proxy.execute(query, body, notificationMsg);
  if (response) {
    const { data } = response;
    commit("updateInventorySlotsList", data);
    return data;
  } else {
    return null;
  }
};

const createShippingLabels = async (_, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const { vendorId } = payload;
  const { fulfillmentId } = payload;
  const body = payload.packages;
  const notificationMsg = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully created label!",
  };
  const query = `orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/parcel`;
  const response = await proxy.execute(query, body, notificationMsg);

  if (response && response.data) {
    return response.data;
  }
};

const addTrackingNumber = async ({ commit }, { payload, fulfillmentId, vendorId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const notificationMsg = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added tracking number!",
  };
  const query = `orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/parcel/tracking-code`;
  const response = await proxy.execute(query, payload, notificationMsg);
  if (response && response.data) {
    const { data } = response;
    return data || [];
  }
};

const getVendorInvoiceByOrderId = async ({ commit }, { vendorId, orderId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/vendor/${vendorId}/purchase/${orderId}/invoice`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setVendorInvoice", data);
    return data;
  }
};

const manualStatusUpdate = async (_, { payload, vendorId, fulfillmentId }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/status`;
  const result = await proxy.execute(query, payload);
  if (result) {
    const { data } = result;
    return data;
  }
};

const cancelCurrentVendorOrder = async (_, { vendorId, fulfillmentId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/cancel`;
  const response = await proxy.execute(query);
  if (response && response.data) {
    return response;
  } else {
    return null;
  }
};

export default {
  fetchWarehouseInventoryLots,
  uniteKitCodes,
  generateKitCodes,
  getVendorInvoiceByOrderId,
  createShippingLabels,
  addTrackingNumber,
  manualStatusUpdate,
  cancelCurrentVendorOrder,
};
