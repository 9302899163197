import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies';

const secretKey = process.env.VUE_APP_PRESCRIPTION_PAYLOAD_SECRET_KEY;

export function encryptData(data) {
  const jsonString = JSON.stringify(data);
  const encryptedData = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
  return encryptedData;
}

export function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
}

export function saveToCookie(key, data) {
  const encryptedData = encryptData(data);
  VueCookies.set(key, encryptedData, '1d'); // Cookie expires in 1 day
}

export function getFromCookie(key) {
  const encryptedData = VueCookies.get(key);
  if (encryptedData) {
    VueCookies.remove(key); // Remove the cookie after reading its value
    return decryptData(encryptedData);
  }
  return null;
}
