import { findIndex, set, forEach } from "lodash";

const setVendorTierPricing = (state, payload) => {
  state.vendorTierPrices = payload;
}

const setVendorClinics = (state, payload) => {
  state.vendorClinics = payload;
}

const updateVendorClinic = (state, payload) => {
  const orgId = payload.orgId;
  const clinicIndex = findIndex(state.vendorClinics, { orgId });

  if (clinicIndex >= 0) {
    set(state.vendorClinics[clinicIndex], payload.prop, payload.value);
  }
};


const updateTierMemberList = (state, payload) => {
  const organizations = payload.body || [];
  const deletedOrgInTiers = payload.deletedOrgInTiers || [];

  if (organizations && organizations.length > 0) {
    forEach(organizations, org => {
      const clinicIndex = findIndex(state.vendorClinics, { orgId: org.organizationId });
      if (clinicIndex >= 0) {
        set(state.vendorClinics[clinicIndex], 'tierId', payload.data.id);
      }
    });
  };

  if (deletedOrgInTiers && deletedOrgInTiers.length > 0) {
    forEach(deletedOrgInTiers, deletedOrg => {
      console.log(deletedOrg, "deletedOrg");
      console.log(state.vendorClinics, "state.vendorClinics");
      const clinicIndex = findIndex(state.vendorClinics, { orgId: deletedOrg.orgId });
      if (clinicIndex >= 0) {
        set(state.vendorClinics[clinicIndex], 'tierId', null);
      }
    });
  }
};

const updateVendorPricingTiers = (state, payload) => {
  if (payload.isAdd) {
    state.vendorTierPrices.push(payload.data);
  } else if (payload.isDelete) {
    const selectedIndex = findIndex(state.vendorTierPrices, { id: payload.id });
    if (selectedIndex >= 0) {
      state.vendorTierPrices.splice(selectedIndex, 1);
    }
  }
};

export default {
  setVendorTierPricing,
  setVendorClinics,
  updateTierMemberList,
  updateVendorPricingTiers,
  updateVendorClinic,
}