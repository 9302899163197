import {
  faCcVisa,
  faCcMastercard,
  faCcDiscover,
  faCcAmex,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import {
  faCogs,
  faEnvelope,
  faPaperPlane,
  faMobileAlt,
  faUser,
  faUserMd,
  faLock,
  faSearch,
  faBars,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faShoppingCart,
  faTachometerAlt,
  faShoppingBag,
  faShoppingBasket,
  faStoreAlt,
  faFileMedicalAlt,
  faQuestionCircle,
  faCircle,
  faFileInvoice,
  faCreditCard,
  faShareAlt,
  faSyringe,
  faExclamationTriangle,
  faCheckCircle,
  faTrash,
  faPrint,
  faWindowClose,
  faHeadset,
  faExclamationCircle,
  faPencilAlt,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faCartPlus,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faArrowRight,
  faArrowLeft,
  faPlus,
  faMinus,
  faInbox,
  faFileDownload,
  faUpload,
  faCheck,
  faFilePrescription,
  faFileAlt,
  faTimesCircle,
  faTimes,
  faDownload,
  faChevronRight,
  faClock,
  faHandshake,
  faHandHoldingMedical,
  faHospitalUser,
  faUserPlus,
  faAddressCard,
  faMapMarked,
  faSave,
} from "@fortawesome/free-solid-svg-icons";

export default {
  faCogs,
  faEnvelope,
  faPaperPlane,
  faMobileAlt,
  faUser,
  faUserMd,
  faLock,
  faSearch,
  faBars,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faShoppingCart,
  faTachometerAlt,
  faShoppingBag,
  faShoppingBasket,
  faStoreAlt,
  faFileMedicalAlt,
  faQuestionCircle,
  faCircle,
  faFileInvoice,
  faCcVisa,
  faCcMastercard,
  faCcDiscover,
  faCcAmex,
  faCreditCard,
  faLinkedinIn,
  faWhatsapp,
  faShareAlt,
  faSyringe,
  faExclamationTriangle,
  faCheckCircle,
  faTrash,
  faWindowClose,
  faPrint,
  faHeadset,
  faExclamationCircle,
  faPencilAlt,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faCartPlus,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faPlus,
  faMinus,
  faInbox,
  faFileDownload,
  faUpload,
  faArrowRight,
  faArrowLeft,
  faCheck,
  faFilePrescription,
  faFileAlt,
  faTimesCircle,
  faTimes,
  faDownload,
  faChevronRight,
  faClock,
  faHandshake,
  faHandHoldingMedical,
  faHospitalUser,
  faUserPlus,
  faAddressCard,
  faMapMarked,
  faSave,
};
