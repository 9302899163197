<template>
  <LoadingScreen :value="loadingLabel" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { cloneDeep, isEmpty, get } from "lodash";
import jwtdecode from "jwt-decode";
import notification from "@/utils/notifications";
import userMe from "@/services/user-me";

import LoadingScreen from "@/components/shared-components/LoadingScreen/LoadingScreen.vue";
import STATES_MFA_REQUIRED from "@/constants/states-mfa-required.js";
import { getFromCookie } from "@/utils/cryptoUtils";
import { convertBase64ToFile } from "@/utils/file-reader-helper";
// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default {
  name: "AuthCallback",
  components: {
    LoadingScreen,
  },
  data() {
    return {
      userData: null,
    };
  },
  computed: {
    ...mapGetters("publicAPIModule", ["getUserIdentity"]),
    ...mapGetters(["getSelectedCurrentLocationId"]),
    isMFARequired() {
      const userPrimaryState = (localStorage.getItem("userPrimaryState")  || "").replace(/\s+/g, "");
      return userPrimaryState && STATES_MFA_REQUIRED.includes(userPrimaryState.toLowerCase());
    },
    loadingLabel() {
      const isFinalizeOrder = localStorage.getItem("finalizeOrder");
      const isEPCSIDProofing = localStorage.getItem("epcsIDProofing");
      if (isFinalizeOrder) {
        return "FINALIZING YOUR ORDER";
      }
      if (isEPCSIDProofing) {
        return "VALIDATING YOUR EPCS ID PROOFING";
      }
      return this.isMFARequired && localStorage.getItem("finalizeOnboarding") ? "FINALIZING THE ONBOARDING" : "LOADING YOUR SCREEN";
    },
  },
  async created() {
    const { isAuthenticated } = await this.$auth;
    const bioteAPIAccessToken = localStorage.getItem("access_token");
    const isEPCSIDProofing = localStorage.getItem("epcsIDProofing");
    const isFinalizeOrder = localStorage.getItem("finalizeOrder");
    if (isFinalizeOrder) {
      await this.proceedOrder();
      localStorage.removeItem("idmecode");
      return;
    }

    if (isEPCSIDProofing) {
      await this.idProofingProcess();
      localStorage.removeItem("idmecode");
      return;
    }

    if (isAuthenticated && !bioteAPIAccessToken) {
      const isFinalizeOnboarding = localStorage.getItem("finalizeOnboarding");

      const idMeResponse = await this.checkIDMeNavigation();
      const { navigateToIdMe, isUserOnboarded } = idMeResponse;

      if (idMeResponse && isFinalizeOnboarding && !isUserOnboarded) {
        await this.handleOnboarding();
        localStorage.removeItem("idmecode");
        return;
      }

      if (idMeResponse && !navigateToIdMe) {
        await this.handleLogin(isUserOnboarded);
      }
    }
  },
  methods: {
    ...mapActions("publicAPIModule", ["fetchBioteUserDetails", "validateAuth0Token", "generateIDMeUrl", "verifyUser", "logoutUser", "onboardOrganization", "idProofUser"]),
    ...mapMutations("onboardingModule", ["appendDataToPracticeUserFormData", "setPreloadedData"]),
    ...mapActions("usersModule", ["initializeCurrentLoggedInInfo", "fetchLoggedUserAccessibleLocations", "initializeLoginDetails"]),
    ...mapMutations("locationsModule", ["setCurrentUserAvailableLocation"]),
    ...mapActions("ordersModule", ["placeOrder"]),
    ...mapActions("filesModule", ["uploadImgFile"]),
    ...mapActions("accountsModule", ["addLocationSignature"]),
    displayAuthorizationError() {
      this.displayNotification(
        "Authorization Required",
        "User must authorize Biote to receive your identity information to gain access to BioteRx. You will be taken back to the beginning of the onboarding process and can authorize Biote to receive your identity information. If you believe you received this message in error, please contact BioteRx customer service at BioteRx@biote.com or 1-844-980-0683.",
        "error",
        15,
      );
      this.redirectToHome(3500);
    },
    async handleOnboarding() {
      const idMeCode = this.getCodeFromUrl();
      const decodedPayload = getFromCookie("onboardingPayload");
      const base64String = localStorage.getItem("signatureImage");
      if (decodedPayload && base64String) {
        const file = convertBase64ToFile(base64String);
        const formData = new FormData();
        formData.append("image", file);
        decodedPayload.idMeCode = idMeCode || null;
        localStorage.removeItem("finalizeOnboarding");
        try {
          const response = await this.onboardOrganization(decodedPayload);
          if (response && response.data) {
            const accessToken = response.data.sessionObject.access_token;
            const decodedToken = await this.initializeLoginDetails(response.data.sessionObject);
            this.displayNotification("Successfully Onboarded!", `User has been onboarded successfully to ${decodedToken.sim.details.name} organization.`, "success");

            if (response.data.locationId) {
              await this.handleSignatureUpload(response.data.locationId, base64String);
            } else {
              this.displaySignatureError();
            }

            await this.initializeCurrentLoggedInInfo({
              access_token: accessToken,
              exp: decodedToken.exp,
              decodedToken,
            });
            this.navigateBasedOnRoles({ decodedToken });
          } else {
            await this.handleOnboardingError(response);
          }
        } catch (error) {
          console.error("Onboarding error: ", error);
          this.redirectToHome();
        }
      }
    },
    async handleOnboardingError(response) {
      if (response.npiError || !response.verified) {
        setTimeout(async () => {
          await this.logoutUser();
        }, 10000);
      } else {
        this.redirectToHome(3500);
      }
    },
    redirectToHome(delay = 0) {
      setTimeout(() => {
        window.location.href = "/";
      }, delay);
    },
    async handleSignatureUpload(locationId, base64String) {
      const file = convertBase64ToFile(base64String);
      const formData = new FormData();
      formData.append("image", file);

      try {
        const uploadResponse = await this.uploadImgFile({ formData });

        if (uploadResponse && uploadResponse.data) {
          const signatureFileLocation = get(uploadResponse, "data", "");
          await this.addLocationSignature({
            locationId,
            payload: { signatureFileLocation },
          });
        } else {
          this.displaySignatureError();
        }
      } catch (error) {
        console.error("Signature upload error: ", error);
        this.displaySignatureError();
      }
    },
    async displaySignatureError() {
      this.displayNotification("Failed to save due to error signature at this time", "Please contact BioteRx Customer Support at BioteRx@biote.com or 1-844-980-0683.");
    },
    displayNotification(message, description, type = "warning", duration = 4.5) {
      this.$notification[type]({
        message,
        description,
        class: `ant-notification-${type}-text`,
        duration,
      });
    },
    async proceedOrder() {
      const idMeCode = this.getCodeFromUrl();
      const decodedPayload = getFromCookie("prescriptionPayload");
      if (decodedPayload && decodedPayload.payload) {
        decodedPayload.payload.idMeCode = idMeCode || null;
        const {
          locationId,
          payload: { patientId },
        } = structuredClone(decodedPayload);
        const params = { locationId, patientId };
        const response = await this.placeOrder(decodedPayload);
        if (response) {
          this.$notification.success({
            message: "Thank you for your purchase!",
            description: (
              <div>
                Your prescription has been successfully completed.
                <br />
                <br />
                Order updates will be available on the patient card or the order history tab.
                <br />
                <br />
                If you have any questions or need further assistance, please contact our support team at BioteRx@biote.com or 844-980-0683.
                <br />
                <br />
                Have a great day!
              </div>
            ),
            class: "ant-notification-success-text",
          });
        }
        localStorage.removeItem("finalizeOrder");
        this.$router.push({ name: "PatientDetails", params });
        return;
      }
    },
    async handleLogin(isUserOnboarded) {
      const response = await this.validateAuth0();
      if (response) {
        const { data, loginDetails } = response;
        if (isUserOnboarded && loginDetails) {
          this.handleSuccessfulLogin({ data, loginDetails });
          return;
        }
        // Onboarding Navigation
        const navigateTo = data && !data.orgOnboarded ? "PracticeInformation" : "PracticeUserAccountSetup";
        this.appendDataToPracticeUserFormData(data);
        // PracticeUserAccountSetup = Non-owner User Navigation
        this.$router.push({ name: navigateTo });
        return { navigateToOnboarding: true }; // To Bypass login
      }
    },
    async handleSuccessfulLogin({ data, loginDetails }) {
      const decodedToken = await this.initializeLoginDetails(loginDetails);
      if (userMe.getMyRoles().isPatientUser) {
        notification("warning", "Unauthorized!", "Please log in as an organization user.");
        await this.logoutUser();
      } else {
        this.navigateBasedOnRoles({ data, decodedToken });
      }
    },
    async generateAuth0Token() {
      const { getAccessTokenSilently } = await this.$auth;
      const auth0AccessToken = await getAccessTokenSilently();
      return auth0AccessToken;
    },
    async validateAuth0() {
      const auth0AccessToken = await this.generateAuth0Token();
      const code = this.getCodeFromUrl();
      const response = await this.validateAuth0Token({ accessToken: auth0AccessToken, idMeCode: code });
      return auth0AccessToken && response ? response : null;
    },
    async initializeLoginDetails(loginDetails) {
      // eslint-disable-next-line camelcase
      const { access_token } = loginDetails;
      const decodedToken = jwtdecode(access_token);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("exp", decodedToken.exp);
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("exp", decodedToken.exp);
      await this.initializeCurrentLoggedInInfo({ access_token, exp: decodedToken.exp, decodedToken });
      return decodedToken;
    },
    async navigateBasedOnRoles({ data, decodedToken }) {
      const { isVendorUser, isSimpatraAdmin } = userMe.getMyRoles();
      const { id } = decodedToken.sim.details; // orgId or vendorId
      const selectedVendor = localStorage.getItem("selected_vendor");
      const hasSelecetedVendor = !["", null, undefined].includes(selectedVendor);
      const callbackRoute = cloneDeep(localStorage.getItem("callbackRoute"));
      const hasCallback = !["", null, undefined].includes(callbackRoute);
      if (hasCallback) {
        this.navigateToCallback(localStorage.getItem("callbackRoute"));
      } else if (isVendorUser || (hasSelecetedVendor && isSimpatraAdmin)) {
        this.$router.push({ path: `/dashboard/vendor/${selectedVendor}` });
      } else {
        await this.navigatePracticeUser({ navigateToSettings: false, id });
      }
    },
    async fetchUserLocations(currentOrgId) {
      const { isSimpatraAdmin } = userMe.getMyRoles();
      let orgId = localStorage.getItem("orgId") || currentOrgId;
      const hasSelectedOrg = !["", null, undefined].includes(orgId);
      // Reset orgId if current user is not a super admin
      orgId = hasSelectedOrg && isSimpatraAdmin ? orgId : currentOrgId;
      localStorage.setItem("orgId", orgId);

      // Fetch current logged in user accessible locations
      const queryParams = isSimpatraAdmin ? { orgId } : {};
      const userAccessibleLocations = await this.fetchLoggedUserAccessibleLocations(queryParams);
      const currentUserAvailableLocations = userAccessibleLocations?.locations || [];
      const userActiveLocations = currentUserAvailableLocations.filter(item => item.isActive === true);
      this.setCurrentUserAvailableLocation(currentUserAvailableLocations);

      let locationId = localStorage.getItem("currentLocation");
      const emptySelectedLocation = ["", null, undefined].includes(locationId);
      // Check current selected location if existed in available locations
      const isLocationExist = currentUserAvailableLocations.find(item => item.id === locationId);
      locationId = isEmpty(isLocationExist) || emptySelectedLocation ? userActiveLocations[0]?.id : locationId;
      localStorage.setItem("currentLocation", locationId);
      return locationId;
    },
    async navigatePracticeUser({ navigateToSettings, id }) {
      const orgId = id;
      const currentSelectedLocation = await this.fetchUserLocations(orgId);
      if (currentSelectedLocation) {
        this.$router.push(`/dashboard/${currentSelectedLocation}/patient-chart`);
      }
    },
    navigateToCallback(callbackRoute) {
      // Navigate to call back when email is still the same on the previous logged
      // Save email in localStorage
      this.$router.push({ path: callbackRoute });
      localStorage.removeItem("callbackRoute");
    },
    async checkIDMeNavigation() {
      try {
        // Generate Auth0 token and identify user for ID proofing
        if (!this.getUserIdentity) {
          await this.logoutUser();
          return;
        }

        // Extract necessary values from the response
        const isUserOnboarded = get(this.getUserIdentity, "isUserOnboarded", false);
        const requireIdProofing = get(this.getUserIdentity, "requireIdProofing", false);
        const primaryState = get(this.getUserIdentity, "primaryState", "");

        localStorage.removeItem("userPrimaryState");
        // Store primary state in local storage
        localStorage.setItem("userPrimaryState", primaryState);

        // Get and decode the access token from local storage

        const userPrimaryState = (primaryState  || "").replace(/\s+/g, "");
        // Determine if MFA is required based on primary state
        const isMFARequired = userPrimaryState && STATES_MFA_REQUIRED.includes(userPrimaryState.toLowerCase());

        // Determine the current route
        const isNotIDMeCallbackRoute = this.$route.name !== "IDMeCallback";

        // If either ID proofing or MFA is needed, generate ID.me URL and navigate
        if (isMFARequired && isUserOnboarded && isNotIDMeCallbackRoute) {
          const idMeUrl = await this.generateIDMeUrl();
          window.location.href = idMeUrl;
          return { navigateToIdMe: true };
        }
        this.userData = { primaryState, requireIdProofing, isUserOnboarded };
        return { navigateToIdMe: false, isUserOnboarded };
      } catch (error) {
        console.error("Error in checkIDMeNavigation:", error);
        return { navigateToIdMe: false, isUserOnboarded };
      }
    },
    getCodeFromUrl() {
      if (this.$route.name === "IDMeCallback") {
        const urlParams = new URLSearchParams(window.location.search);
        const error = urlParams.get("error") || null;
        if (error === "access_denied") {
          return 401;
        }
        const idMeCode = urlParams.get("code") || null;
        if (idMeCode === null) {
          notification(NOTIFICATION_CONSTANTS.ERROR, "ID.me Unauthorized!", "Token not found!");
        }
        return idMeCode;
      }
      return null;
    },
    getErrorFromURL() {
      if (this.$route.name === "IDMeCallback") {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("error") || null;
      }
      return null;
    },
    async idProofingProcess() {
      const idMeCode = this.getCodeFromUrl();
      const locationdId = this.getSelectedCurrentLocationId;
      await this.idProofUser({
        locationId: this.getSelectedCurrentLocationId,
        isAccepted: true,
        idMeCode,
      });
      localStorage.removeItem("epcsIDProofing");
      this.$router.push(`/dashboard/${locationdId}/patient-chart`);
    },
  },
};
</script>

<style type="scss" scoped></style>

