import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import SHIPPING_CARRIER_CONSTANT from "@/constants/shipping-carrier-constant";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const fetchVendorInfo = async ({ commit }, payload) => {
  try {
    const isPayloadObject = typeof payload === "object";
    const isResultOnly = isPayloadObject && payload.isResultOnly;
    const vendorId = isPayloadObject ? payload.vendorId : payload;
    const response = await axios.get(`/accounts/v1/vendor/${vendorId}`);
    if (!isResultOnly) {
      commit("setVendorInfo", response.data);
    }
    return response && response.data;
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const updateVendorInfo = async ({ commit, getters }, payload) => {
  try {
    const vendorId = payload.id;
    const vendorOldCarrier = structuredClone(getters.getVendorInfo && getters.getVendorInfo.shippingCarrierId);
    const body = {
      name: payload.name,
      phone: payload.phone,
      fax: payload.fax,
      email: payload.email,
      website: payload.website,
      address1: payload.address1,
      address2: payload.address2,
      city: payload.city,
      region: payload.region,
      postalCode: payload.postalCode,
      country: payload.country || "US",
      orderEmail: payload.orderEmail,
      orderCutoff: payload.orderCutoff,
      orderCutoffTz: payload.orderCutoffTz,
      handlingTime: payload.handlingTime,
      shippingCarrier: payload.shippingCarrier,
      description: payload.description,
      shippingTypeId: payload.shippingTypeId,
      shippingType: payload.shippingTypeId === 0 ? "SIMPATRASHIPPING" : "SELFSHIPPING",
      orderServiceId: payload.orderServiceId || "",
    };
    const shippingCarrierMapped = Object.values(SHIPPING_CARRIER_CONSTANT).find(carrier => carrier.id === payload.shippingCarrierId);
    body.shippingCarrier = shippingCarrierMapped ? shippingCarrierMapped.value : '';
    const oldShippingCarrier = Object.values(SHIPPING_CARRIER_CONSTANT).find(carrier => carrier.id === vendorOldCarrier);
    const response = await axios.put(`/accounts/v1/vendor/${vendorId}`, body);
    if (response) {
      notification("success", "Successfully updated vendor info.");
      commit("setVendorInfo", response.data);
      if (shippingCarrierMapped.value !== (oldShippingCarrier && oldShippingCarrier.value) && !payload.isEditOrderService) {
        notification(
          "warning",
          "We partially added default shipping to all product that requires custom shipping service level, please ensure they properly align with new carrier."
        );
      }
      return response;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const addShippingRateToInvoice = async (_, payload) => {
  try {
    const { locationId, purchaseId, shippingRates } = payload;
    await axios.post(`orders/v1/buyer/${locationId}/purchase/${purchaseId}/shipping`, shippingRates);
    notification("success", "Successfully added shipping rate");
  } catch (error) {
    return null;
  }
};

const inviteAsVendor = async ({ commit }, { body }) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `j-idp/v1/vendor/invite`;
  const response = await proxy.execute(query, body, {});
  if (response) {
    return response;
  }
};

const addNewVendor = async (_, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `accounts/v1/vendor/`;
  const response = await proxy.execute(query, payload);
  if (response) {
    return response;
  }
};

export default {
  fetchVendorInfo,
  updateVendorInfo,
  addShippingRateToInvoice,
  inviteAsVendor,
  addNewVendor,
};
